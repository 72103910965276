/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "palsie/sier2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "palsie/sier2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(relativePath: { eq: "palsie/sier1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `M.Pals - Glad pleisterwerk voor de wand`,
  textWithLink: () => (
    <>
      <p>
        Sierpleister stucwerk is de afwerking van het raap- of pleisterwerk.
        Omdat sierpleister het eindresultaat van de muren bepaalt, is het
        belangrijk om het door een professionele stukadoor te laten aanbrengen.
        Sierpleister is een mooie en praktische afwerking van uw muren.
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0629575549'>
          <Button
            role='button'
            variant='contained'
            className='btn heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
    </>
  ),
};

const textBottom = {
  textWithLink: () => (
    <>
      <AniLink fade to='/wandafwerking'>
        <Button
          role='button'
          variant='contained'
          className='btn heading__button'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const Sierpleister = ({ data, path, location }) => {
  const seo = {
    title: `Sierpleister`,
    description: `Voorzie je huis van sierpleister en maak je huis je thuis..`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'M.Pals Stukadoors',
    imgTitle: 'M.Pals Stukadoors',
    header: 'M.Pals - Sierpleister',
    textWithLink: () => (
      <>
        <p>
          Veel sierpleisters kunnen wij in kleur aanbrengen, waarmee wij u de
          moeite van zelf schilderen uit handen nemen. Het voordeel van stucwerk
          in kleur is dat er geen kleurverschil te zien is bij kleine
          beschadigingen. Verder is sierpleister boenvast en dus makkelijk
          schoon te maken.
        </p>
        <ul>
          <li>Boen- en stootvast;</li>
          <li>Aan te brengen in een kleur naar keuze;</li>
          <li>Kleurecht;</li>
          <li>Zowel voor binnen als buiten geschikt.</li>
        </ul>
      </>
    ),
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Sierpleister`,
    pitchLine: `Voorzie je huis van sierpleister en maak je huis je thuis..`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        textBottom={textBottom}
      />
    </>
  );
};

Sierpleister.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Sierpleister;
